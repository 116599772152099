
import { computed, defineComponent } from '@vue/runtime-core';
import { i18n, LANGUAGE, translate } from '@/features/i18n/i18n-translate';

const ostein: { [key in LANGUAGE]: string } = {
    [LANGUAGE.NO]: 'Starter 1. juni',
    [LANGUAGE.EN]: 'Starting June 1',
};

export default defineComponent({
    setup: () => ({
        title: computed(() => translate('page', 'home', 'persons', 'title')),
        boss: computed(() => translate('page', 'home', 'persons', 'roles', 'boss')),
        notboss: computed(() => translate('page', 'home', 'persons', 'roles', 'notboss')),
        support: computed(() => translate('page', 'home', 'persons', 'roles', 'support')),
        consultant: computed(() => translate('page', 'home', 'persons', 'roles', 'consultant')),
        startingOstein: computed(() => ostein[i18n.locale]),
    }),
});
