<template>
    <section class="page-persons">
        <div class="page-persons__container">
            <h2 class="page-persons__title">{{ title }}</h2>

            <figure class="page-persons__person">
                <img class="page-persons__image" src="/persons/mikal-skov.jpg" alt="Bilde av Mikael Skov" />
                <figcaption class="page-persons__name">Mikael Skov</figcaption>
                <address class="page-persons__contact-info">
                    <p class="page-persons__role">{{ boss }}</p>
                    <a class="page-persons__email" href="mailto:mikael@mako.as">mikael@mako.as</a>
                    <a class="page-persons__phone" href="tel:004791114005">+47 911 14 005</a>
                    <a
                        class="page-persons__linkedin"
                        target="blank"
                        href="https://www.linkedin.com/in/mikael-skov-a7079183/"
                        >LinkedIn</a
                    >
                </address>
            </figure>
            <figure class="page-persons__person">
                <img
                    class="page-persons__image"
                    src="/persons/christoffer-jacobsen.jpg"
                    alt="Bilde av Christoffer Jacobsen"
                />
                <figcaption class="page-persons__name">Christoffer Jacobsen</figcaption>
                <address class="page-persons__contact-info">
                    <p class="page-persons__role">{{ notboss }}</p>
                    <a class="page-persons__email" href="mailto:christoffer@mako.as">christoffer@mako.as</a>
                    <a class="page-persons__phone" href="tel:004747908083">+47 479 08 083</a>
                    <a
                        class="page-persons__linkedin"
                        target="blank"
                        href="https://www.linkedin.com/in/christoffer-s-jacobsen/"
                        >LinkedIn</a
                    >
                </address>
            </figure>
            <figure class="page-persons__person">
                <img class="page-persons__image" src="/persons/lars-h-sorebo.jpg" alt="Bilde av Lars H. Sørebø" />
                <figcaption class="page-persons__name">Lars H. Sørebø</figcaption>
                <address class="page-persons__contact-info">
                    <p class="page-persons__role">{{ support }}</p>
                    <a class="page-persons__email" href="mailto:lars@mako.as">lars@mako.as</a>
                    <a class="page-persons__phone" href="tel:004740239808">+47 402 39 808</a>
                </address>
            </figure>
            <figure class="page-persons__person">
                <img
                    class="page-persons__image"
                    src="/persons/oystein-brattetaule.jpg"
                    alt="Bilde av Øystein Brattetaule"
                />
                <figcaption class="page-persons__name">Øystein Brattetaule</figcaption>
                <address class="page-persons__contact-info">
                    <p class="page-persons__role">{{ consultant }}</p>
                    <a class="page-persons__email" href="mailto:oystein@mako.as">oystein@mako.as</a>
                    <a class="page-persons__phone" href="tel:004740400503">+47 404 00 503</a>
                    <a
                        class="page-persons__linkedin"
                        target="blank"
                        href="https://www.linkedin.com/in/%C3%B8ystein-brattetaule-92a1733/"
                        >LinkedIn</a
                    >
                </address>
            </figure>
            <figure class="page-persons__person">
                <img
                    class="page-persons__image"
                    src="/persons/dag-inge-hanakam.jpg"
                    alt="Bilde av Øystein Brattetaule"
                />
                <figcaption class="page-persons__name">Dag Inge Hanakam</figcaption>
                <address class="page-persons__contact-info">
                    <p class="page-persons__role">{{ consultant }}</p>
                    <a class="page-persons__email" href="mailto:dag@mako.as">dag@mako.as</a>
                    <a class="page-persons__phone" href="tel:004747324404">+47 473 24 404</a>
                    <a
                        class="page-persons__linkedin"
                        target="blank"
                        href="https://www.linkedin.com/in/dag-inge-hanakam-90b5092/"
                        >LinkedIn</a
                    >
                </address>
            </figure>
        </div>
    </section>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/runtime-core';
import { i18n, LANGUAGE, translate } from '@/features/i18n/i18n-translate';

const ostein: { [key in LANGUAGE]: string } = {
    [LANGUAGE.NO]: 'Starter 1. juni',
    [LANGUAGE.EN]: 'Starting June 1',
};

export default defineComponent({
    setup: () => ({
        title: computed(() => translate('page', 'home', 'persons', 'title')),
        boss: computed(() => translate('page', 'home', 'persons', 'roles', 'boss')),
        notboss: computed(() => translate('page', 'home', 'persons', 'roles', 'notboss')),
        support: computed(() => translate('page', 'home', 'persons', 'roles', 'support')),
        consultant: computed(() => translate('page', 'home', 'persons', 'roles', 'consultant')),
        startingOstein: computed(() => ostein[i18n.locale]),
    }),
});
</script>

<style lang="scss">
.page-persons {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    &__container {
        width: 100%;
        @include container;
        flex-flow: row wrap;
        justify-content: center;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        @include breakpoint(medium-large) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
    &__title {
        line-height: 1.5;
        font-size: 1.5rem;
        text-align: center;
        width: 100%;
        margin-bottom: 2.5rem;
        @include breakpoint(medium-large) {
            margin-bottom: 5rem;
        }
    }
    &__person {
        position: relative;
        overflow: hidden;
        max-width: 20rem;
        width: 100%;
        margin-bottom: 1.25rem;
        @include breakpoint(medium) {
            width: calc(50% - 1.25rem);
            margin-right: 0.625rem;
            margin-left: 0.625rem;
        }
        @include breakpoint(medium-large) {
            width: calc(33.33% - 1.25rem);
            margin-right: 0.625rem;
            margin-left: 0.625rem;
            margin-bottom: 4rem;
        }
        @include breakpoint(large) {
            width: calc(33.33% - 4rem);
            margin-right: 2rem;
            margin-left: 2rem;
            margin-bottom: 4rem;
        }
        &--comming:after {
            content: attr(data-kommer);
            background: var(--secondary);
            color: #fff;
            text-align: center;
            transform: rotate(45deg);
            position: absolute;
            font-size: 1rem;
            line-height: 2rem;
            width: 14rem;
            top: 2.25rem;
            right: -4rem;
        }
    }
    &__image {
        width: 100%;
        margin-bottom: 0.625rem;
    }
    &__name {
        color: var(--secondary);
        font-weight: bold;
        font-size: 1.1rem;
        @include breakpoint(medium-large) {
            font-size: 1.25rem;
        }
    }
    &__contact-info {
        display: flex;
        flex-flow: column;
    }
    &__role,
    &__email,
    &__phone,
    &__linkedin {
        display: inline-flex;
        text-decoration: none;
        font-style: normal;
        margin-top: 0.625rem;
        &:hover {
            color: var(--secondary);
        }
    }
}
</style>
